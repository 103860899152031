import "./document_card.css";
import { Document, timestampToString } from "../services/firestore";

function DocumentCard(doc: Document, onClick: () => void) {
  return (
    <div className="documentCard" onClick={() => onClick()} key={doc.id}>
      <div className="documentCardContent">
        {/* <div className="documentCardBlurText">{doc.content}</div> */}
        <h3 className="documentCardTitle">{doc.title}</h3>
        <div className="bottomBar">
          <p className="documentCardDate">
            {timestampToString(doc.dateEdited)}
          </p>
          <div className="documentCardDots">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentCard;
