import { user } from "./auth";
import { db } from "./firebase"
import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore"; 

export type UserData = {
    documents: Document[];
    profile: Profile;
}

export type Document = {
    id: string;
    title: string;
    dateEdited: Timestamp;
    dateCreated: Timestamp;
}

export type Profile = {
    name: string;
    email: string;
    photoUrl: string;
}

function monthToString(month: number) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[month];
}

function dateNumberToString(date: number) {
    // e.g. 1st, 18th
    const dateString = date.toString();
    const lastDigit = dateString[dateString.length - 1];
    switch (lastDigit) {
        case "1":
            return dateString + "st";
        case "2":
            return dateString + "nd";
        case "3":
            return dateString + "rd";
        default:
            return dateString + "th";
    }
}


export function timestampToString(timestamp: Timestamp) {
    const date = timestamp.toDate();
    const year = date.getFullYear();
    const month = date.getMonth() ;
    const day = date.getDate();

    if (year === new Date().getFullYear()) {
        return `${monthToString(month)} ${dateNumberToString(day)}`;
    } else {
        return `${monthToString(month)} ${dateNumberToString(day)}, ${year}`;
    }
}

export async function userData(): Promise<UserData | null> {
    var userData = {} as UserData;
    const _user = await user();
    if (!_user) {
        return null;
    }
    userData.profile = {
        name: _user.displayName ?? "",
        email: _user.email ?? "",
        photoUrl: _user.photoURL ?? "",
    };
    const userRef = doc(db, "users", _user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
        userData.documents = userDoc.data().documents ?? [];
        userData.documents.sort((a, b) => {
            return b.dateEdited.seconds - a.dateEdited.seconds;
        });
    } else {
        saveDefaultData();
        userData.documents = [];
    }
    return userData;
}

export async function saveDefaultData() {
    const _user = await user();
    if (!_user) {
        return;
    }
    const userRef = doc(db, "users", _user.uid);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
        await setDoc(userRef, {
            documents: [],
        });
    }
}

export async function addNewDocument(id: string) {
    const _user = await user();
    if (!_user) {
        return;
    }
    const userRef = doc(db, "users", _user.uid);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
        return;
    }
    const documents = userDoc.data().documents;
    documents.push({
        id: id,
        title: "Untitled",
        dateEdited: Timestamp.now(),
        dateCreated: Timestamp.now(),
    });
    await setDoc(userRef, {
        documents: documents,
    });
}

export async function updateTitle(id: string, title: string) {
    const _user = await user();
    if (!_user) {
        return;
    }
    const userRef = doc(db, "users", _user.uid);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
        return;
    }
    const documents = userDoc.data().documents;
    const index = documents.findIndex((doc: Document) => doc.id === id);
    if (index === -1) {
        return;
    }
    documents[index].title = title;
    await setDoc(userRef, {
        documents: documents,
    });
}