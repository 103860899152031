import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './login';
import Home from './home';
import Editor from './editor';
import { setupDatabase } from './services/database';
import { user } from './services/auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    loader: async ({ request }) => {
      await user()
      return null;
    },
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/edit/:id",
    loader: async ({ request, params }) => {
      if (params.id !== undefined) {
        await setupDatabase(params.id);
        return {
          id: params.id
        }
      }
      return undefined;
    },
    element: <Editor />,
  }
]);

root.render(
  // <React>
    <RouterProvider router={router} />
  // </React>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
