import { registerListener, writeDocDelta } from "../services/database";
import { userId } from "../services/auth";

export let controllers: Map<number, EditorController> = new Map();

export function getCheckpointContents() {
    let ops: any = {};
    controllers.forEach((controller: EditorController, sectionId: number) => {
        ops[sectionId] = controller.getEditorContents();
    });
    return ops;
}

export class EditorController {
  private docId: string = "";
  public quill: any;
  public sectionId: number = 0;

  constructor(docId: string, quill: any, sectionId: number) {
    this.docId = docId;
    this.quill = quill;
    this.sectionId = sectionId;
    this.quill.on("text-change", (delta: any, oldDelta: any, source: string) =>
      this.onEditorChange(delta, source)
    );

    registerListener(sectionId, (deltas: any) => {
        this.quill.updateContents({ ops: this.decompress(deltas) });
    }, (deltas: any) => {
        this.quill.setContents({ ops: this.decompress(deltas) });
    });

    controllers.set(sectionId, this);
  }

  destroy() {
    controllers.delete(this.sectionId);
  }

  getEditorContents() {
    return this.compress(this.quill.getContents().ops);
  }

  onEditorChange(delta: any, source: string) {
    if (source !== "user") return;
    writeDocDelta(this.docId, this.compress(delta.ops), userId() ?? "", this.sectionId);
  }

  compress(ops: any) {
    return ops.map((op: any) => {
      if (op.insert) {
        op.i = op.insert;
        delete op.insert;
      }

      if (op.attributes) {
        op.a = op.attributes;
        delete op.attributes;
      }

      if (op.delete) {
        op.d = op.delete;
        delete op.delete;
      }

      if (op.retain) {
        op.r = op.retain;
        delete op.retain;
      }

      // Realtime database doesn't like null values, so find all and convert to false recursively
      const findNull = (obj: any) => {
        for (let key in obj) {
          if (obj[key] === null) {
            obj[key] = false;
          } else if (typeof obj[key] === "object") {
            findNull(obj[key]);
          }
        }
      };

      findNull(op);

      return op;
    });
  }

  decompress(ops: any) {
    return ops.map((op: any) => {
      if (op.i) {
        op.insert = op.i;
        delete op.i;
      }

      if (op.a) {
        op.attributes = op.a;
        delete op.a;
      }

      if (op.d) {
        op.delete = op.d;
        delete op.d;
      }

      if (op.r) {
        op.retain = op.r;
        delete op.r;
      }

      // Convert false values back to null recursively
      // const findFalse = (obj: any) => {
      //     for (let key in obj) {
      //         if (obj[key] === false) {
      //             obj[key] = null;
      //         } else if (typeof obj[key] === 'object') {
      //             findFalse(obj[key]);
      //         }
      //     }
      // }

      // TODO: Research all fields that are either false or null
      // findFalse(op);

      return op;
    });
  }
}
