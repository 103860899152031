import { Unsubscribe, child, get, onChildAdded, onValue, push, query, ref, runTransaction, serverTimestamp, set, startAt, update } from "@firebase/database";
import { auth, database } from "./firebase";
import { updateTitle } from "./firestore";
import { getCheckpointContents } from "../editor/editor_controller";

const CHECKPOINT_INTERVAL = 5;

let title = "Untitled";
let nextRevision = 0;
let sections: Sections = {};
let latestCheckpoint: any;
let deltasSinceCheckpoint = new Array<any>();
let listeners = new Map<number, Function>();
let unsubs = new Array<Unsubscribe>();

export async function createNewDoc() {
    if (auth.currentUser === null) {
        throw new Error("Not signed in");
    }
    const newDocId = push(ref(database, "documents"), {
        "title": "Untitled",
        "sections": {
            "AAA": {
                "id": 0,
                "title": ""
            }
        },
        "users": [{
            "id": auth.currentUser.uid,
            "role": "author"
        }],
        "history": {},
        "checkpoint": null
    }).key;
    return newDocId;
}

export async function getLatestCheckpoint(id: string) {
    const snapshot = await get(child(ref(database), `documents/${id}/checkpoint`));
    if (snapshot.exists()) {
        return snapshot.val();
    } else {
        return null;
    }
}

export async function addSection(id: string, orderId: string, sectionId: number) {
    await set(child(ref(database), `documents/${id}/sections/${orderId}`), {
        "id": sectionId
    });
}

export async function deleteSection(id: string, orderId: string) {
    await update(child(ref(database), `documents/${id}/sections/${orderId}`), {
        "deleted": true
    });
    sections[orderId].deleted = true;
    sectionListeners.forEach((listener) => {
        listener(sections);
    });
}

export async function setSectionTitle(id: string, orderId: string, title: string) {
    await set(child(ref(database), `documents/${id}/sections/${orderId}/title`), title);
}

export async function setTitle(id: string, title: string) {
    await set(child(ref(database), `documents/${id}/title`), title);
    await updateTitle(id, title);
}

export async function getTitle(id: string) {
    const snapshot = await get(child(ref(database), `documents/${id}/title`));
    if (snapshot.exists()) {
        return snapshot.val();
    } else {
        return null;
    }
}

export async function setDocFromId(id: string, data: any) {
    await set(child(ref(database), `documents/${id}`), data);
}

export async function writeDocDelta(id: string, deltas: any, authorId: string, sectionId: number) {
    await runTransaction(ref(database, `documents/${id}/history/${revisionToId(nextRevision)}`), (currentData) => {
        if (currentData === null) {
            if (nextRevision % CHECKPOINT_INTERVAL === 0 && nextRevision !== 0) {
                storeCheckpoint(id, revisionToId(nextRevision), getCheckpointContents(), authorId);
            }
            nextRevision++;
            return {
                a: authorId,
                o: deltas,
                s: sectionId,
                t: serverTimestamp()
            };
        } else {
            console.log("WARNING: Document already exists, not overwriting.");
        }
    });
}

export async function storeCheckpoint(id: string, revision: string, content: any, authorId: string) {
    await set(child(ref(database), `documents/${id}/checkpoint`), {
            a: authorId,
            id: revision,
            o: content,
    });
}

export async function listenForDocDeltas(id: string) {
    const start = revisionToId(nextRevision);
    const q = query(ref(database, `documents/${id}/history`), startAt(null, start));
    const unsubChildAdded = onChildAdded(q, (snapshot) => {
        const delta = snapshot.val();
        if (revisionFromId(snapshot.key!) >= nextRevision) {
            nextRevision = revisionFromId(snapshot.key!) + 1;
            deltasSinceCheckpoint.push(delta);
            callListeners(delta);
        }
    });

    unsubs.push(unsubChildAdded);
}

let titleListeners = new Map<number, Function>();

export async function registerTitleListener(sectionId: number, update: (title: string) => void) {
    titleListeners.set(sectionId, update);
}

let sectionListeners = new Array<Function>();

export async function listenForSectionChanges(id: string, update: (sections: Sections) => void) {
    sectionListeners.push(update);
    
    if (sections !== undefined) {
        update(sections);
 
        Object.values(sections).forEach((section) => {
            if (titleListeners.has(section.id)) {
                titleListeners.get(section.id)!(section.title);
            }
        });
    }
    const unsubSections = onValue(ref(database, `documents/${id}/sections`), (snapshot) => {
        if (snapshot.exists()) {
            sections = snapshot.val();
            update(snapshot.val());

            snapshot.forEach((child) => {
                const sectionId = child.val().id;
                if (titleListeners.has(sectionId)) {
                    titleListeners.get(sectionId)!(child.val().title);
                }
            });
        }
    });

    unsubs.push(unsubSections);
}

export async function listenForTitleChanges(id: string, update: (title: string) => void) {
    if (title) {
        update(title);
    }
    const unsubTitle = onValue(ref(database, `documents/${id}/title`), (snapshot) => {
        update(snapshot.val());
    });

    unsubs.push(unsubTitle);
}

export async function registerListener(sectionId: number, update: (delta: any) => void, set: (delta: any) => void) {    
    if (latestCheckpoint !== undefined && latestCheckpoint.o[sectionId] !== undefined) {
        set(latestCheckpoint.o[sectionId]);
    }

    if (deltasSinceCheckpoint.length > 0) {
        deltasSinceCheckpoint.forEach((delta) => {
            if (delta.s === sectionId) {
                update(delta.o);
            }
        });
    }

    listeners.set(sectionId, (delta: any, isSet: boolean) => {
        if (delta.s !== sectionId) {
            return;
        }
        if (isSet) {
            set(delta.o);
        } else {
            update(delta.o);
        }
    });
}

export async function unregisterListener(sectionId: number) {
    listeners.delete(sectionId);
}

export async function callListeners(delta: any, isSet: boolean = false) {
    if (listeners.has(delta.s)) {
        listeners.get(delta.s)!(delta, isSet);
    } 
}

export async function setupDatabase(docId: string) {
    title = await getTitle(docId);
    const checkpoint = await getLatestCheckpoint(docId);

    if (checkpoint) {
        latestCheckpoint = checkpoint;
        nextRevision = revisionFromId(checkpoint.id) + 1;
        checkpoint.o.forEach((ops: any, sectionId: number) => {
            callListeners({
                a: checkpoint.a,
                o: ops,
                s: sectionId,
                t: checkpoint.t
            }, true);
        });
    }

    await listenForDocDeltas(docId);
}

export function dispose() {
    unsubs.forEach((unsub) => {
        unsub();
    });
    unsubs = new Array<Unsubscribe>();
}

var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export function revisionToId(revision: number) {
    if (revision === 0) {
        return 'A0';
    }

    var str = '';
    while (revision > 0) {
        var digit = (revision % characters.length);
        str = characters[digit] + str;
        revision -= digit;
        revision /= characters.length;
    }

    // Prefix with length (starting at 'A' for length 1) to ensure the id's sort lexicographically.
    var prefix = characters[str.length + 9];
    return prefix + str;
}

export function revisionFromId(revisionId: string) {
    var revision = 0;
    for(var i = 1; i < revisionId.length; i++) {
        revision *= characters.length;
        revision += characters.indexOf(revisionId[i]);
    }
    return revision;
}

export type Sections = {[key: string]: {id: number, title: string, deleted?: boolean}};