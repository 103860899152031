import { useNavigate } from "react-router-dom";
import { signOut } from "../services/auth";
import { useEffect, useState } from "react";
import { UserData, addNewDocument, userData } from "../services/firestore";
import "./index.css";
import DocumentCard from "./document_card";
import { createNewDoc } from "../services/database";

export default function Home() {
  const navigate = useNavigate();
  const [data, setUserData] = useState(undefined as UserData | undefined);
  const [profileCardOpen, setProfileCardOpen] = useState(false);

  function createDoc() {
    createNewDoc().then((docId) => {
      if (docId === undefined) {
        return;
      }
      addNewDocument(docId!);
      navigate(`/edit/${docId}`);
    });
  }

  function _signOut() {
    signOut().then(() => navigate("/"));
  }

  function getUserData() {
    userData().then((data) => {
      if (data) {
        setUserData(data);
      }
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="main">
      <div className="header">
        <h1>JustDraft</h1>
        <div className="searchBar">
          <input type="text" placeholder="Search" />
        </div>
        <div>
          <button onMouseEnter={() => setProfileCardOpen(true)}>
            <img src={data?.profile.photoUrl} className="profile-photo" alt="profile"></img>
          </button>
          <div onMouseLeave={() => setProfileCardOpen(false)} className={`profile-card ${profileCardOpen ? "enabled" : ""}`}>
            <div className="content">
              <div className="info">
                <h2>{data?.profile.name}</h2>
                <p>{data?.profile.email}</p>
              </div>
              <button className="signout" onClick={_signOut}>SIGN OUT</button>
            </div>
            <button onClick={() => setProfileCardOpen(false)}>
              <img src={data?.profile.photoUrl} className="profile-photo" alt="profile"></img>
            </button>
          </div>
        </div>
      </div>
      <div className="recent">
        <h2>RECENT</h2>
        <div className="documents">
          <div className="documentCard new" onClick={createDoc}>
            <h3>NEW</h3>
          </div>
          {data?.documents.map((doc, index) => DocumentCard(doc, () => navigate(`/edit/${doc.id}`)))}
        </div>
      </div>
    </div>
  );
}
