import { useEffect } from "react";
import "./index.css";
import apple from "../assets/social-login/Apple.png";
import google from "../assets/social-login/Google.png";
import { signIn, user } from "../services/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  function signInWith(provider: string) {
    signIn(provider).then((success) => {
      if (success) {
        navigate("/home");
      }
    });
  }

  useEffect(() => {
    user().then((user) => {
      if (user) {
        navigate("/home");
      }
    });
  }, [navigate]);

  return (
    <div className="Login">
      <h1>JustDraft</h1>
      <div className="social-login-buttons">
        <button onClick={() => signInWith('apple')}>
          <img src={apple} alt="Sign In With Apple" />
        </button>
        <button onClick={() => signInWith('google')}>
          <img src={google} alt="Sign In With Google" />
        </button>
      </div>
    </div>
  );
}
