import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";

var uid: string | null = null;

export async function user() {
  await auth.authStateReady();
  uid = auth.currentUser?.uid ?? null;
  return auth.currentUser;
}

export function userId() {
  return uid ?? auth.currentUser?.uid;
}

export async function signIn(method: string): Promise<boolean> {
  switch (method) {
    case "apple":
      return await signInWithApple();
    case "google":
      return await signInWithGoogle();
    default:
      return false;
  }
}

export async function signOut() {
  await auth.signOut();
}

export function signInWithApple(): Promise<boolean> {
  const provider = new OAuthProvider("apple.com");
  return signInWithPopup(auth, provider)
    .then((result) => {
      uid = result.user?.uid ?? null;
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function signInWithGoogle() {
  const provider = new OAuthProvider("google.com");
  return signInWithPopup(auth, provider)
    .then((result) => {
      uid = result.user?.uid ?? null;
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
