import Quill from "quill";
import React, { useEffect, useRef } from "react";
import "./quill_editor.css";
import { EditorController } from "./editor_controller";
import { SectionContext } from "./context_menu";
import { registerTitleListener, setSectionTitle } from "../services/database";

function QuillEditor({docId, section, orderId, setCurrentContext, isFocused}: {docId: string, section: any, orderId: string, setCurrentContext: any, isFocused: boolean}) {
    const titleField = useRef<HTMLInputElement>(null);

    function saveTitle() {
        setSectionTitle(docId, orderId, titleField.current!.value);
    }

    function titleChanged() {
        setCurrentContext(new SectionContext(titleField.current?.value ?? section.title, section.id, orderId, docId));
    }

    useEffect(() => {
        var FontAttributor = Quill.import('attributors/class/font');
        FontAttributor.whitelist = [
            'inter'
        ];
        Quill.register(FontAttributor, true);
        const quill = new Quill('#editor' + section.id, {
            theme: 'bubble',
        });

        quill.root.onfocus = (e) => {
            setCurrentContext(new SectionContext(titleField.current?.value ?? section.title, section.id, orderId, docId));
        }

        registerTitleListener(section.id, (title: string) => {
            if (titleField.current !== null) {
                titleField.current.value = title;
            }
        });

        if (section.title !== "" && section.title !== undefined) {
            titleField.current!.value = section.title;
        }

        new EditorController(docId, quill, section.id);
    // eslint-disable-next-line
    }, []);

    return (
        <div className={`editor ${isFocused ? 'active' : ''}`} id={"editorParent" + section.id}>
             <input 
                ref={titleField}
                type="text"
                className="section-title"
                placeholder="Untitled Section"
                onSubmit={() => {titleField.current!.blur()}} 
                onBlur={() => {saveTitle()}}
                onChange={() => {titleChanged()}}
                onClick={() => {setCurrentContext(new SectionContext(titleField.current?.value ?? section.title, section.id, orderId, docId))}}
            />
            <div id={"editor" + section.id} className="quill" />
            {/* <div className="bottom-bar">
                <div className="section-settings">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </div>
            </div> */}
        </div>
    )
}

export default React.memo(QuillEditor);