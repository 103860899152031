import { useLoaderData } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import QuillEditor from "./quill_editor";
import { Sections, addSection, listenForSectionChanges, listenForTitleChanges, revisionFromId, revisionToId, setTitle } from "../services/database";
import './index.css';
import ContextMenu, { EmptyContext, MenuContext, SectionContext } from "./context_menu";
import DocumentOptions from "./document_options";

export default function Editor() {
    const data: any = useLoaderData();
    const textfield = useRef<HTMLInputElement>(null);
    const [sections, setSections]: [Sections, any] = useState({});
    const [currentContext, setCurrentContext] = useState<MenuContext>(new EmptyContext());
    const currentContextRef = useRef(currentContext);
    currentContextRef.current = currentContext;

    useEffect(() => {
        listenForTitleChanges(data.id, (title: string) => {
            if (textfield.current !== null) {
                textfield.current.value = title;
            }
        });

        listenForSectionChanges(data.id, (newSections: Sections) => {
            setSections(newSections);

            Object.values(newSections).forEach((section: any) => {
                if (currentContextRef.current instanceof SectionContext && currentContextRef.current.sectionId === section.id && section.deleted) {
                    setCurrentContext(new EmptyContext());
                }
            });
        });
    }, [data.id]);

    function titleChanged() {
        setTitle(data.id, textfield.current!.value);
    }

    function newSection() {
        let orderId: number = 0;
        Object.keys(sections).forEach(key => {
            orderId = Math.max(orderId, revisionFromId(key) + 100);
        });

        let sectionId: number = 0;
        Object.values(sections).forEach((section: any) => {
            sectionId = Math.max(sectionId, section.id + 1);
        });

        addSection(data.id, revisionToId(orderId), sectionId);
    }
    
    return (
        <div className="main-editor">
            <DocumentOptions title={textfield.current?.value ?? "Untitled"} sections={sections} />
            <div className="content">
                <input 
                    ref={textfield} 
                    type="text" 
                    className="title" 
                    placeholder="Title" 
                    onSubmit={() => {textfield.current!.blur()}} 
                    onBlur={() => {titleChanged()}}
                />
                <div className="sections">
                    {Object.keys(sections).filter((s) => !sections[s].deleted).sort((a, b) => revisionFromId(a) - revisionFromId(b)).map(orderId => (
                        <QuillEditor 
                            key={orderId}
                            docId={data.id}
                            section={sections[orderId]}
                            orderId={orderId}
                            setCurrentContext={setCurrentContext} 
                            isFocused={
                                currentContext instanceof SectionContext && 
                                currentContext.sectionId === sections[orderId].id
                            } />
                    ))}
                </div>
                <button onClick={() => newSection()} className="add-section button">Add Section</button>
            </div>
            { currentContext instanceof EmptyContext ? null :
            <ContextMenu context={currentContext} />
            }
        </div>
    )
}