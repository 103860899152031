import TrashDelete from "../assets/icons/delete";
import { deleteSection } from "../services/database";
import "./context_menu.css";

export abstract class MenuContext {
    title?: string;
    type?: string;
    enabled: boolean = false;
}

export class EmptyContext extends MenuContext {
    constructor() {
        super();
        this.title = "";
        this.type = "empty";
        this.enabled = false;
    }
}

export class SectionContext extends MenuContext {
    title = "Section";
    sectionId: number;
    orderId: string;
    docId: string;

    constructor(title: string, sectionId: number, orderId: string, docId: string) {
        super();
        this.title = title;
        this.type = "section";
        this.sectionId = sectionId;
        this.orderId = orderId;
        this.docId = docId;
        this.enabled = true;
    }
}

export default function ContextMenu({context}: {context: MenuContext}) {
    function _deleteSection() {
        if (context.type === "section") {
            let sectionContext: SectionContext = context as SectionContext;
            deleteSection(sectionContext.docId, sectionContext.orderId);
        }
    }

    return (
        <>
            <div className="context-menu-relative"></div>
            <div className="context-menu">
                <div className="context-menu-row">
                <h2>{context.title}</h2>
                {context.type === "section" &&
                <button className="delete" onClick={() => _deleteSection()}>
                    <TrashDelete  />
                </button>}
                </div>
            </div>
        </>
    )
}