import { saveAs } from 'file-saver';
import Delta from 'quill-delta';
import { pdfExporter } from 'quill-to-pdf';
import { EditorController, controllers } from '../editor/editor_controller';
import { Sections } from './database';

// let config: Config = {
//     styles: {
//         normal: {
//             font: 'Inter',
//             fontSize: 12,
//             baseIndent: 72,
//             levelIndent: 0,
//         },
//         header_1: {
//             font: 'Inter-Bold',
//             fontSize: 16,
//             baseIndent: 72,
//             levelIndent: 0
//         },
//         header_2: {
//             font: 'Inter-Bold',
//             fontSize: 14,
//             baseIndent: 72,
//             levelIndent: 0
//         },
//         block_quote: {
//             font: 'Inter-Italic',
//             fontSize: 12,
//             italics: true,
//             baseIndent: 72,
//             levelIndent: 0,
//             indent: {
//                 left: 0,
//                 right: 0
//             }
//         },
//         code_block: {
//             font: 'Courier',
//             fontSize: 12,
//             baseIndent: 72,
//             levelIndent: 0
//         },
//         list_paragraph: {
//             font: 'Inter',
//             fontSize: 12,
//             baseIndent: 50,
//             levelIndent: 25
//         },
//         citation: {
//             font: 'Inter',
//             fontSize: 12,
//             baseIndent: 72,
//             levelIndent: 0
//         }
//     },
// };

export async function exportQuillToPdf(title: string, sections: Sections) {
    let deltas: Delta[] = [];
    var sectionIdToTitle = new Map<number, string>();
    Object.values(sections).forEach((section) => {
        sectionIdToTitle.set(section.id, section.title);
    });

    let titleDelta = new Delta();
    titleDelta.insert(title, { bold: true, size: 'huge' });
    deltas.push(titleDelta);

    controllers.forEach((controller: EditorController, _: number) => {
        let sectionTitle = new Delta();
        sectionTitle.insert(sectionIdToTitle.get(controller.sectionId)!, { bold: true });
        deltas.push(sectionTitle);
        deltas.push(controller.quill.getContents());
    });

    // let fontBuffer = await fetch("https://rsms.me/inter/font-files/InterVariable.ttf");
    // let arrayBufferCopy: Uint8Array = new Uint8Array(await fontBuffer.arrayBuffer());

    const pdfAsBlob = await pdfExporter.generatePdf(deltas);
    saveAs(pdfAsBlob, `${title}.pdf`);
}