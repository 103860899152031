import { exportQuillToPdf } from '../services/export_utilities';
import './document_options.css';
import { Sections } from '../services/database';

export default function DocumentOptions({title, sections}: {title: string, sections: Sections}) {
    
    function sectionIndex(orderId: string) {
        return Object.keys(sections).sort((a, b) => sections[a].id - sections[b].id).indexOf(orderId);
    }

    function exportPdf() {
        exportQuillToPdf(title, sections);
    }
    
    return (
        <div className="document-options">
            <h2>{title}</h2>
            <div className="sections">
                {Object.keys(sections).filter((s) => !sections[s].deleted).sort((a, b) => sections[a].id - sections[b].id).map(orderId => (
                    <div className="section" key={orderId}>
                        <h3><span>{sectionIndex(orderId) + 1}.</span>{sections[orderId].title}</h3>
                    </div>
                ))}
            </div>
            <button className='button' onClick={() => exportPdf()}>Export</button>
        </div>
    )
}