import React from 'react'
 export default function TrashDelete({color = "#000000"}) {
 return (
 <>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} fill="none" d="M17.5 6L17.12 12.13C16.76 12.04 16.38 12 16 12C13.24 12 11 14.24 11 17C11 19.67 13.09 21.85 15.72 21.98C15.67 22 15.62 22 15.56 22H6.44C5.91 22 5.47 21.59 5.44 21.06L4.5 6H17.5Z" clipRule="evenodd" fillRule="evenodd"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} d="M13.5 2H8.5V3H13.5V2Z"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} fill="none" d="M18.5 6H3.5C3.22 6 3 5.78 3 5.5V3.5C3 3.22 3.22 3 3.5 3H18.5C18.78 3 19 3.22 19 3.5V5.5C19 5.78 18.78 6 18.5 6Z" clipRule="evenodd" fillRule="evenodd"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} fill="none" d="M16 22C18.7614 22 21 19.7614 21 17C21 14.2386 18.7614 12 16 12C13.2386 12 11 14.2386 11 17C11 19.7614 13.2386 22 16 22Z"></path>
<path strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} d="M13.88 14.88L18.12 19.12"></path>
<path strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" stroke={color} d="M18.12 14.88L13.88 19.12"></path>
</svg>
</>
)}